import React from "react"
import { FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import FullWidthImage from "../components/full-width-image"
import SEO from "../components/seo"
import ContentWrapper from "../components/content-wrapper"

const IndexPage = ({ pageContext: { locale } }) => {
  return (
    <Layout locale={locale}>
      <SEO page="home" />
      <FullWidthImage />
      <ContentWrapper>
        <h1>
          <FormattedMessage id="welcome_to_eurobram" />
        </h1>

        <h2>
          <FormattedMessage id="our_company_headline" />
        </h2>
        <p>
          <FormattedMessage id="our_company_text" />
        </p>

        <h2>
          <FormattedMessage id="our_customers_headline" />
        </h2>
        <p>
          <FormattedMessage id="our_customers_text" />
        </p>

        <h2>
          <FormattedMessage id="our_competence_headline" />
        </h2>
        <p>
          <FormattedMessage id="our_competence_text" />
        </p>
      </ContentWrapper>
    </Layout>
  )
}

export default IndexPage
